/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import 'theme/fonts';
@import 'theme/variables.scss';

@import 'swiper/css';

html {
    background-color: var(--ion-color-primary);

    &.ios {
        body:not(.offline-device) {
            .ios-notch-top {
                margin-top: constant(safe-area-inset-top); /* iOS 11.0 */
                margin-top: env(safe-area-inset-top); /* iOS 11.2 */
            }
        }
        .offline-device {
            margin-top: constant(safe-area-inset-top); /* iOS 11.0 */
            margin-top: env(safe-area-inset-top); /* iOS 11.2 */

            .ios-notch-bottom {
                margin-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
                margin-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
            }

            .ios-notch-modal {
                margin-bottom: 45px;
            }

            ion-toolbar {
                padding: 0;
            }
        }
    }
}

.login-toast-offline {
    --background: var(--ion-color-medium);
    --border-color: var(--ion-color-medium);
    --color: var(--ion-color-light);
}

.modal-signature-canvas {
    canvas {
        border: 1px solid var(--ion-color-dark-shade);
    }
}

.textarea-wrapper,
textarea.native-textarea {
    height: 100%;
}

.toast-success,
.toast-error {
    --background: var(--ion-color-light);
    --color: var(--ion-color-dark);
}

.picker-toolbar.sc-ion-picker-md,
.picker-toolbar.sc-ion-picker-ios {
    justify-content: space-between;
}

.picker-button.picker-button-text,
.picker-button.picker-button-text.ion-activated {
    text-transform: capitalize;
}

ion-picker {
    .picker-wrapper {
        --background: var(--ion-color-primary-shade);
        color: var(--ion-color-medium);

        .picker-button {
            color: var(--ion-color-medium);

            &.save {
                color: var(--ion-color-secondary);
            }

            &.ion-activated {
                color: var(--ion-color-medium);
            }
        }

        .picker-columns {
            padding: 10px 0 0 0;
        }

        .picker-opt-selected {
            color: var(--ion-color-light);
        }
    }
}

ion-datetime {
    --background: var(--ion-color-primary-shade);
    --background-rgb: 7, 32, 50;
    --wheel-highlight-border-radius: 48px;
    --wheel-fade-background-rgb: 7, 32, 50;
}

ion-datetime::part(wheel-item) {
    color: var(--ion-color-medium);
}

ion-datetime::part(wheel-item active) {
    color: var(--ion-color-secondary);
}

.sc-ion-picker-legacy-md {
    &.picker-wrapper {
        --background: var(--ion-color-primary-tint);
    }
    &.picker-toolbar {
        justify-content: center;
    }
    &.picker-button {
        color: white;
        &.cancel {
            color: var(--ion-color-medium);
        }
        &.save {
            color: var(--ion-color-secondary);
        }
    }
    &.picker-opt {
        color: var(--ion-color-medium);
    }
    &.picker-opt-selected {
        color: var(--ion-color-secondary);
    }
}

@media only screen and (min-width: 768px) and (min-height: 768px) {
    .large-modal-class {
        --height: 35% !important;
    }
}
