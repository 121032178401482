@font-face {
    font-family: 'Proxima Nova Regular', sans-serif;
    src: url('../assets/fonts/ProximaNova-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'inscyth';
    src: url('../assets/fonts/inscyth.eot?uys0te');
    src: url('../assets/fonts/inscyth.eot?uys0te#iefix') format('embedded-opentype'),
        url('../assets/fonts/inscyth.ttf?uys0te') format('truetype'),
        url('../assets/fonts/inscyth.woff?uys0te') format('woff'),
        url('../assets/fonts/inscyth.svg?uys0te#inscyth') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'inscyth' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-message:before {
    content: '\e92a';
}
.icon-done:before {
    content: '\e924';
}
.icon-today:before {
    content: '\e925';
}
.icon-upcoming:before {
    content: '\e926';
}
.icon-install-2:before {
    content: '\e927';
}
.icon-delivery-2:before {
    content: '\e92b';
}
.icon-measure-2:before {
    content: '\e928';
}
.icon-template-3:before {
    content: '\e929';
}
.icon-config:before {
    content: '\e922';
}
.icon-home:before {
    content: '\e923';
}
.icon-no-photo:before {
    content: '\e921';
}
.icon-app-version:before {
    content: '\e91d';
}
.icon-dark-mode:before {
    content: '\e91e';
}
.icon-light-mode:before {
    content: '\e91f';
}
.icon-logout:before {
    content: '\e920';
}
.icon-gallery:before {
    content: '\e91b';
}
.icon-upload-photo:before {
    content: '\e91c';
}
.icon-check:before {
    content: '\e919';
}
.icon-close:before {
    content: '\e91a';
}
.icon-signature:before {
    content: '\e918';
}
.icon-add:before {
    content: '\e913';
}
.icon-agreement-of-completion:before {
    content: '\e914';
}
.icon-CIEA:before {
    content: '\e915';
}
.icon-delete:before {
    content: '\e916';
}
.icon-order-confirmation:before {
    content: '\e917';
}
.icon-add-note:before {
    content: '\e90f';
}
.icon-back:before {
    content: '\e910';
}
.icon-change-status:before {
    content: '\e911';
}
.icon-edit:before {
    content: '\e912';
}
.icon-account:before {
    content: '\e900';
}
.icon-calendar:before {
    content: '\e901';
}
.icon-call:before {
    content: '\e902';
}
.icon-error:before {
    content: '\e903';
}
.icon-filter:before {
    content: '\e904';
}
.icon-install:before {
    content: '\e905';
}
.icon-jobs:before {
    content: '\e906';
}
.icon-locations:before {
    content: '\e907';
}
.icon-measure:before {
    content: '\e908';
}
.icon-overdue:before {
    content: '\e909';
}
.icon-sms:before {
    content: '\e90a';
}
.icon-sync:before {
    content: '\e90b';
}
.icon-template:before {
    content: '\e90c';
}
.icon-visit-store-cfi:before {
    content: '\e90d';
}
.icon-visit-store-sales:before {
    content: '\e90e';
}
