// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
    /** Gimbil APP defaults **/
    --ion-font-family: 'Proxima Nova Regular', sans-serif;
    --action-directions-background: #3479f6;
    --action-call-background: #395675;
    --action-message-background: #2a8b18;
    --appointment-status-open-color: #3479f6;
    --appointment-status-completed-color: #93d222;
    --appointment-status-canceled-color: #dc3a40;

    /** primary **/
    --ion-color-primary: #072032;
    --ion-color-primary-rgb: 7, 32, 50;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #030d18;
    --ion-color-primary-tint: #213444;

    /** secondary **/
    --ion-color-secondary: #93bf58;
    --ion-color-secondary-rgb: 88, 138, 24;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #4d7915;
    --ion-color-secondary-tint: #598a18;

    /** tertiary **/
    --ion-color-tertiary: #5260ff;
    --ion-color-tertiary-rgb: 82, 96, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;

    /** success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    /** danger **/
    --ion-color-danger: #dc3a40;
    --ion-color-danger-rgb: 220, 58, 64;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #c23338;
    --ion-color-danger-tint: #e04e53;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #b3b3b3;
    --ion-color-medium-rgb: 122, 122, 122;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #6b6b6b;
    --ion-color-medium-tint: #878787;

    /** light **/
    --ion-color-light: #ffffff;
    --ion-color-light-rgb: 255, 255, 255;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #e0e0e0;
    --ion-color-light-tint: #ffffff;
}
